import { Injectable } from '@angular/core';

import {
  defferSerialization,
  HttpApiPersistenceService,
  IEnhancedPaginatedResponse,
  IPaginatedResponse,
  ISerializedApiService,
} from '@locumsnest/core/src';
import {
  getEntityFields,
  optionalDateField,
  Serializer,
} from '@locumsnest/core/src/lib/serializers';

import { LocumsNestEndpointConfig } from '../../core/constants';
import { IProfileBankDetailsEntity } from '../../interfaces/api/profile-bank-details-entity';
import { IProfileEntity } from '../../interfaces/api/profile-entity';
import { IProfileSearchFormState } from './interfaces';

const ENDPOINT = defferSerialization({ endpoint: 'profile' });

@Injectable({
  providedIn: 'root',
})
export class ProfilePersistenceService
  extends HttpApiPersistenceService<
    LocumsNestEndpointConfig,
    IProfileEntity,
    IPaginatedResponse<IProfileEntity>,
    IProfileEntity | IProfileSearchFormState
  >
  implements ISerializedApiService<'profile', IProfileEntity>
{
  readonly endpoint = ENDPOINT;
  readonly serializer = new Serializer(
    getEntityFields<IProfileEntity>({
      registrationFrom: optionalDateField,
      registrationTo: optionalDateField,
      dateOfVaccineDeclaration: optionalDateField,
    }),
  );

  searchRegistration(profileEntity: IProfileSearchFormState) {
    return this.create(
      { ...profileEntity },
      { controllerResource: 'profileSearch', skipSerializer: true },
    );
  }

  getBankDetails(id: string) {
    return this.retrieve<IEnhancedPaginatedResponse<IProfileBankDetailsEntity>>(
      { profile: id },
      { controllerResource: 'profileBankDetails', skipSerializer: true },
    );
  }

  profileUnregisteredSearch(profileEntity: IProfileSearchFormState, professionId, email) {
    const data = {
      id: null,
      profession: professionId,
      first_name: profileEntity.firstName,
      last_name: profileEntity.lastName,
      email,
    };

    return this.create(data, {
      controllerResource: 'profileUnregisteredSearch',
      skipSerializer: true,
    });
  }
}
